import { BUILD_ENV } from 'gatsby-env-variables'

import { datadogRum } from '@datadog/browser-rum'

const init = () => {
  datadogRum.init({
    applicationId: 'f5ebd3cc-583d-4f54-8ce8-ac4bc511be38',
    clientToken: 'pub62c0b883b79dceba9b00f236d4194c26',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'itau-jn6-app-recovery-www',
    env: BUILD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

export default {
  init,
}
